@charset "utf-8";
@use 'sass:color';
@use 'sass:math';

// Define defaults for each variable.
$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #d6d3cc !default;
$background-color: #18191a !default;
$brand-color:      #61a8ff !default;

$grey-color:       #eee !default;
// We're switching this theme to dark mode, so light is dark and dark is light.
$grey-color-light: color.scale($grey-color, $lightness: -40%) !default;
$grey-color-dark:  color.scale($grey-color, $lightness: 25%) !default;

$table-text-align: left !default;

$content-width:    800px !default;
$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: math.div($spacing-unit, 2);
//     padding-left: math.div($spacing-unit, 2);
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout"
  // We have our own syntax theme.
  // "minima/syntax-highlighting"
;

// Override some things from those imports.
.site-header {
  border-top: none;
}

a:hover {
  color: $brand-color;
}

pre,
code {
  background-color: transparent;
}

.svg-icon {
  color: #{$grey-color};
}

.footer-col-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.footer-col-1, .footer-col-2, .footer-col-3 {
  width: auto;
}

// Somehow the defaults make h1 smaller than h2.
h1 {
  @include relative-font-size(2.3);

  @include media-query($on-laptop) {
    @include relative-font-size(2.0);
  }
}

// This is our custom blockquote class, it's not in the theme.
blockquote.sidenote {
  color: $text-color;
  background: lighten($background-color, 10%);
  border-left: 6px solid $grey-color-dark;
  padding: math.div($spacing-unit, 2);
  @include relative-font-size(1.0);
  letter-spacing: normal;
  font-style: normal;
}

// Import our syntax coloring theme, Dracula.
@import '../_includes/dracula'
